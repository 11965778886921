import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useMemo, useState, } from 'react';
import styled from '@emotion/styled/macro';
import { CircularProgress, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { formatNumber } from '@shared/lib/numbers';
import { cancelEditStep, setQueuePosition, setSubscriptionPrice, submitStep, } from '@entities/portfolio';
import { useGetPortfolioCopiersQuery, useGetPortfolioCopiersQueueDetailsQuery, } from '@shared/api/portfolio';
import { useAppDispatch, useAppSelector } from '@app/store/Hooks';
import { ReactComponent as PlusIcon } from '@icons/wolfkit-light/plus-light.svg';
import { useGetPlatformBalanceQuery } from '@shared/api/current-user';
import Button from '@shared/ui/buttons/Button';
import { Body, BodyMedium } from '@components/styled/Typography';
import QuestionMarkTooltip from '@shared/ui/tooltips/QuestionMarkTooltip';
import QuantityInput from '@shared/ui/inputs/QuantityInput';
import { CenterSpacedRow, ContainerColumn, ContainerRow } from '@components/styled';
import { PortfolioSubscribers, PortfolioSubscriptionPrice } from '@entities/portfolio';
import { clamp } from '@utils/math';
import { debounceWrapper } from '@utils/async-utils';
import { IsDefined } from '@utils/js-ts';
import BidToQueueStep from '../BidToQueueStep';
import QueuePositionDetails from './QueuePositionDetails';
const Column = styled(ContainerColumn)(() => ({
    height: 'auto',
}));
const StepContainer = styled(ContainerColumn)(props => ({
    height: 'auto',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: props.theme.spacing_sizes.l,
}));
const SubscriptionPriceColumn = styled(ContainerColumn)(props => ({
    height: 'auto',
    gap: props.theme.spacing_sizes.m,
}));
const StepRow = styled(ContainerRow)(() => ({
    height: 'auto',
    alignItems: 'start',
    justifyContent: 'space-between',
}));
const QuantityInputStyled = styled(QuantityInput)(() => ({
    width: 200,
}));
const LoaderContainer = styled(ContainerColumn)(() => ({
    alignItems: 'center',
    justifyContent: 'center',
    height: 'auto',
    minHeight: 100,
}));
const searchClosestSubscription = (arr, 
// TODO: enforce the type check of these keys and the PortfolioSubscription
targetValueKey, targetValue) => {
    if (!arr.length) {
        return undefined;
    }
    let left = 0;
    let right = arr.length - 1;
    let mid;
    while (left <= right) {
        mid = Math.floor((left + right) / 2);
        if (arr[mid][targetValueKey] === targetValue) {
            return {
                match: arr[mid],
                matchType: 'exact',
            };
        }
        if (arr[mid][targetValueKey] > targetValue) {
            if (targetValueKey === 'position') {
                right = mid - 1;
            }
            else {
                // opposite for 'bid' key goes here, because array is sorted by position
                left = mid + 1;
            }
        }
        else {
            // eslint-disable-next-line no-lonely-if
            if (targetValueKey === 'position') {
                left = mid + 1;
            }
            else {
                right = mid - 1;
            }
        }
    }
    return {
        match: arr[left] || arr[mid !== null && mid !== void 0 ? mid : right] || arr[right],
        matchType: 'closest',
    };
};
const SelectQueuePositionCollapsed = ({ queuePosition, copiersCount, }) => {
    const { subscriptionData } = useAppSelector(state => state.portfolioSubscription);
    const theme = useTheme();
    return (_jsxs(_Fragment, { children: [_jsx(PortfolioSubscribers, { followersCount: copiersCount, userQueuePosition: queuePosition }), _jsx(PortfolioSubscriptionPrice, { price: subscriptionData === null || subscriptionData === void 0 ? void 0 : subscriptionData.subscriptionPrice, priceColor: theme.palette.text.primary })] }));
};
const StepType = 'bid_amount';
const SyncBidAmountAndSlotPositionDebounceDelay = 350;
const BidAmountStep = 5;
const GetCopiersQueryTake = 50;
const SelectQueuePosition = ({ stepNumber, portfolio, queueDetails, platformBalance, }) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const dispatch = useAppDispatch();
    const copiersCount = useMemo(() => queueDetails.copiers_count + 1, [queueDetails]);
    const [currentChunk, setCurrentChunk,] = useState(Math.ceil(copiersCount / GetCopiersQueryTake) - 1);
    const [copiersChunkMap, setCopiersChunkMap] = useState({});
    const skip = useMemo(() => currentChunk * GetCopiersQueryTake, [currentChunk]);
    const hasMoreChunks = useMemo(() => Object.keys(copiersChunkMap).length < Math.ceil(copiersCount / GetCopiersQueryTake), [copiersChunkMap, copiersCount]);
    const copiers = useMemo(() => Object.values(copiersChunkMap).reduce((arr, chunk) => arr.concat(chunk), []), [copiersChunkMap]);
    const [lastChangedValue, setLastChangedValue] = useState();
    const [price, setPrice] = useState((portfolio === null || portfolio === void 0 ? void 0 : portfolio.subscriptionPrice) || 0);
    const [position, setPosition] = useState(copiersCount);
    const isBalanceLow = useMemo(() => price > platformBalance, [price, platformBalance]);
    const { subscriptionData } = useAppSelector(state => state.portfolioSubscription);
    const { data: copiersChunk, isSuccess: copiersChunkIsSuccess, isFetching: copiersChunkIsFetching, isError: copiersChunkIsError, } = useGetPortfolioCopiersQuery({
        portfolioId: portfolio === null || portfolio === void 0 ? void 0 : portfolio.id,
        skip,
        take: GetCopiersQueryTake,
    });
    const syncBidAmountAndSlotPosition = useCallback((changedValue) => {
        const searchClosestResult = searchClosestSubscription(copiers, changedValue.key, changedValue.value);
        if (!searchClosestResult) {
            return;
        }
        let approxChunkIndex = 0;
        if (changedValue.key === 'position') {
            approxChunkIndex = Math.floor(changedValue.value / GetCopiersQueryTake);
        }
        else if (changedValue.key === 'bid' &&
            IsDefined(queueDetails) &&
            IsDefined(queueDetails.avg_price_per_position)) {
            const maxPrice = queueDetails.avg_price_per_position * copiersCount;
            const chunkPrice = queueDetails.avg_price_per_position * GetCopiersQueryTake;
            approxChunkIndex = Math.floor(maxPrice / chunkPrice) -
                Math.floor((changedValue.value / chunkPrice));
        }
        approxChunkIndex = clamp(approxChunkIndex, 0, Math.floor(copiersCount / GetCopiersQueryTake));
        if (searchClosestResult.matchType === 'closest' &&
            hasMoreChunks) {
            // NOTE: if users bid amount is bigger than the closest found match
            // and we have more chunks to load, we do so because there are cases
            // when the approximate calculation of index doesn't get the right index
            // example: Math.floor(505 / 253 = 1.99) = 1
            if (IsDefined(copiersChunkMap[approxChunkIndex]) &&
                changedValue.key === 'bid' &&
                searchClosestResult.match.bid < changedValue.value) {
                approxChunkIndex -= 1;
                approxChunkIndex = clamp(approxChunkIndex, 0, Math.floor(copiersCount / GetCopiersQueryTake));
            }
            if (!IsDefined(copiersChunkMap[approxChunkIndex])) {
                // NOTE: `setCurrentChunk` will trigger the fetch of additional data,
                // and then the search process will repeat
                setCurrentChunk(approxChunkIndex);
                setLastChangedValue(changedValue);
                return;
            }
        }
        // NOTE: if the search result is exact match or it is closest,
        // but there is no more data to fetch, we want to set the matched values
        // NOTE: for the exact same bid amount user will be placed next to the user,
        // that is already placed that bid amount for his/her position
        // NOTE: same goes if user selected position, his bid amount will be increased
        // on the `BidAmountStep` in order to be placed infront of the previous user
        // on that position
        if (changedValue.key === 'bid') {
            // NOTE: if our bid is greater we shouldn't move user a position behind
            const positionStep = changedValue.value > searchClosestResult.match.bid ? 0 : 1;
            setPrice(changedValue.value);
            setPosition(searchClosestResult.match.position + positionStep);
        }
        else if (changedValue.key === 'position') {
            // NOTE: for the last position we shouldn't increase the price
            const bidAmountStep = changedValue.value !== copiersCount ? BidAmountStep : 0;
            setPrice(searchClosestResult.match.bid + bidAmountStep);
            setPosition(changedValue.value);
        }
        setLastChangedValue(undefined);
    }, [
        copiers,
        copiersCount,
        copiersChunkMap,
        hasMoreChunks,
        queueDetails,
    ]);
    const syncBidAmountAndSlotPositionDebounce = useMemo(() => debounceWrapper(syncBidAmountAndSlotPosition, SyncBidAmountAndSlotPositionDebounceDelay), [syncBidAmountAndSlotPosition]);
    const onBidAmountChange = (value) => {
        setPrice(value);
        syncBidAmountAndSlotPositionDebounce({ key: 'bid', value });
    };
    const onSlotPositionChange = (value) => {
        setPosition(value);
        syncBidAmountAndSlotPositionDebounce({ key: 'position', value });
    };
    useEffect(() => {
        if (copiersChunkIsFetching || copiersChunkIsError) {
            return;
        }
        if (copiersChunk && copiersChunk.length) {
            setCopiersChunkMap((previousValue) => (Object.assign(Object.assign({}, previousValue), { [currentChunk]: copiersChunk })));
        }
    }, [
        currentChunk,
        copiersChunk,
        copiersChunkIsFetching,
        copiersChunkIsError,
    ]);
    useEffect(() => {
        if (IsDefined(lastChangedValue) &&
            copiersChunkIsSuccess) {
            syncBidAmountAndSlotPosition(lastChangedValue);
        }
    }, [
        copiersChunkIsSuccess,
        lastChangedValue,
        syncBidAmountAndSlotPosition,
    ]);
    const onAction = (actionType) => {
        if (actionType === 'continue') {
            dispatch(setSubscriptionPrice(price));
            dispatch(setQueuePosition(position));
            dispatch(submitStep(StepType));
        }
        else if (actionType === 'update') {
            dispatch(setSubscriptionPrice(price));
            dispatch(setQueuePosition(position));
            dispatch(cancelEditStep(StepType));
        }
        else if (actionType === 'cancel' && IsDefined(subscriptionData)) {
            if (IsDefined(subscriptionData.subscriptionPrice)) {
                setPrice(subscriptionData.subscriptionPrice);
            }
            if (IsDefined(subscriptionData.queuePosition)) {
                setPosition(subscriptionData.queuePosition);
            }
        }
    };
    const errorMessage = useMemo(() => t('portfolio.subscription.queue_error'), [t]);
    return (_jsx(BidToQueueStep, { type: 'bid_amount', stepNumber: stepNumber, title: t('portfolio.subscription.set_subscription_price_label'), tooltipText: t('portfolio.subscription.set_subscription_price_tooltip'), disabled: isBalanceLow || copiersChunkIsFetching, onAction: onAction, 
        // TODO: select users data if already subscribed
        collapsedContent: (_jsx(SelectQueuePositionCollapsed, { queuePosition: position, copiersCount: copiersCount })), errorMessage: errorMessage, children: _jsxs(StepContainer, { children: [_jsxs(SubscriptionPriceColumn, { children: [_jsxs(StepRow, { children: [_jsxs(Column, { children: [_jsxs(CenterSpacedRow, { children: [_jsx(BodyMedium, { children: t('portfolio.subscription.subscription_price') }), _jsx(QuestionMarkTooltip, { title: t('portfolio.subscription.subscription_price_tooltip'), placement: 'top-start', arrow: true })] }), _jsx(Body, { color: theme.palette.text.secondary, children: t('portfolio.subscription.platform_balance_available', {
                                                balance: formatNumber(platformBalance, { compactNotation: true }),
                                            }) })] }), _jsx(QuantityInputStyled, { value: price, incDecStep: BidAmountStep, min: portfolio.subscriptionPrice, errorMessage: (isBalanceLow ?
                                        t('portfolio.subscription.error_low_balance') :
                                        undefined), roundToMultiplesOfStep: true, onChange: onBidAmountChange, fullWidth: true, isLoading: IsDefined(lastChangedValue) &&
                                        lastChangedValue.key === 'position' &&
                                        copiersChunkIsFetching })] }), isBalanceLow && (_jsx(StepRow, { children: _jsx(Button, { variant: 'filled', color: 'primary', size: 'large', fullWidth: true, startIcon: PlusIcon, children: t('portfolio.subscription.add_balance') }) }))] }), _jsxs(StepRow, { children: [_jsxs(Column, { children: [_jsx(BodyMedium, { children: t('portfolio.subscription.queue_position') }), _jsx(Body, { color: theme.palette.text.secondary, children: t('portfolio.subscription.queue_position_description') })] }), _jsx(QuantityInputStyled, { variant: 'position', value: position, positionsCount: copiersCount, min: 1, max: copiersCount, incDecStep: 1, onChange: onSlotPositionChange, fullWidth: true, isLoading: IsDefined(lastChangedValue) &&
                                lastChangedValue.key === 'bid' &&
                                copiersChunkIsFetching })] }), _jsx(QueuePositionDetails, { usersPosition: position })] }) }));
};
const SelectQueuePositionStep = ({ stepNumber, }) => {
    const { portfolio } = useAppSelector(state => state.portfolioSubscription);
    const { data: platformBalance, isFetching: platformBalanceIsFetching, isError: platformBalanceIsError, } = useGetPlatformBalanceQuery();
    const { data: copiersQueueDetails, isFetching: copiersQueueDetailsIsFetching, isError: copiersQueueDetailsIsError, } = useGetPortfolioCopiersQueueDetailsQuery({
        portfolioId: String(portfolio === null || portfolio === void 0 ? void 0 : portfolio.id),
    });
    const isFetching = useMemo(() => platformBalanceIsFetching || copiersQueueDetailsIsFetching, [platformBalanceIsFetching, copiersQueueDetailsIsFetching]);
    const isError = useMemo(() => platformBalanceIsError || copiersQueueDetailsIsError, [platformBalanceIsError, copiersQueueDetailsIsError]);
    return (_jsxs(_Fragment, { children: [isFetching && (_jsx(LoaderContainer, { children: _jsx(CircularProgress, {}) })), !isFetching &&
                !isError &&
                IsDefined(portfolio) &&
                IsDefined(copiersQueueDetails) &&
                IsDefined(platformBalance) && (_jsx(SelectQueuePosition, { stepNumber: stepNumber, portfolio: portfolio, queueDetails: copiersQueueDetails, platformBalance: platformBalance }))] }));
};
export default SelectQueuePositionStep;
